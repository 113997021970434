export default function ModalBodyInputs(props: PropsModalBodyInputs) {
  return (
    <div className={"px-3 mb-6 md:mb-0 " + props.cols}>
      <label className="block uppercase tracking-wide text-gray-800 dark:text-gray-100 text-xs font-bold mb-2 text-left">
        {props.label}
      </label>
      <input
        value={props.value ? props.value : ""}
        className="appearance-none block w-full bg-gray-700 dark:bg-gray-200 text-gray-100 dark:text-gray-800 border rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        type="text"
        disabled
      />
    </div>
  );
}

interface PropsModalBodyInputs {
  label: string;
  value: any;
  cols: string;
}
