import { useState } from "react";
import useForm from "../../hooks/useForm";
import EyeIcon from "./../../icons/eye.svg";

export default function FormInput(props: PropsFormImputs) {
  // STATES for PASS TYPE
  const [passwordShown, setPasswordShown] = useState(false);

  // UPPER CONTEXT FORM
  const { handleFormChange, getFormValue } = useForm();

  // Button Show Pass
  const btnShowPass = () => {
    if (props.type === "password")
      return (
        <button
          type="button"
          className="absolute bottom-4 right-0 p-0.5 bg-gray-500  border rounded hover:bg-gray-400 dark:hover:bg-gray-600"
          onClick={() => {
            setPasswordShown(!passwordShown);
          }}
        >
          <img src={EyeIcon} alt="Ver Contraseña Icon" />
        </button>
      );
  };

  // Type
  const typeInput = () => {
    let data = props.type;
    if (props.type === "password") {
      return passwordShown ? (data = "text") : (data = "password");
    }
    return data;
  };

  return (
    <div className={(props.clases ? props.clases : "") + " relative"}>
      <>
        <label className="block uppercase tracking-wide text-gray-800 dark:text-gray-100 text-xs font-bold mb-2 text-left">
          {props.label}
        </label>
        <input
          required={props.required}
          name={props.name}
          className={
            props.type === "checkbox" || props.type === "radio"
              ? ""
              : "appearance-none block w-full bg-gray-700 dark:bg-gray-200 text-gray-100 dark:text-gray-800 border rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-900 dark:focus:bg-gray-100"
          }
          type={typeInput()}
          onChange={handleFormChange}
          value={props.value ? props.value : getFormValue(props.name)}
          checked={
            props.type === "radio" && getFormValue(props.name) == props.value
              ? true
              : false
          }
        />
        {btnShowPass()}
      </>
    </div>
  );
}

interface PropsFormImputs {
  label: string;
  type: string;
  name: string;
  clases?: string;
  required?: boolean;
  value?: number | string;
}
