import { useEffect, useState } from "react";
import ModalBodyInputs from "../../../componentes/Modales/ModalBodyInputs";
import BtnViewTabla from "../../../componentes/Tabla/Componentes/BtnViewTabla";
import { Contacto } from "../../../modelos/Contacto.model";
import { Direccion } from "../../../modelos/Direccion.model";
import { Proveedor } from "../../../modelos/Proveedores.model";

export default function ModalProveedoresBody(props: PropsModalProveedoresBody) {
  // STATES
  const [contactos, setContactos] = useState<Contacto[]>([]);

  // Effect
  useEffect(() => {
    setContactos([]);
  }, [props.refresh]);

  return (
    <>
      {/* PROVEEDOR */}
      {props.proveedor ? (
        <div className="-mx-3 mb-6 grid grid-cols-2 sm:grid-cols-6 lg:grid-cols-12">
          {/* CODIGO */}
          <ModalBodyInputs
            label="Código"
            value={props.proveedor.codigo}
            cols="col-span-1"
          />

          {/* NOM COMERCIAL */}
          <ModalBodyInputs
            label="Nombre Comercial"
            value={props.proveedor.nombrecomercial}
            cols="col-span-3"
          />

          {/* NIF */}
          <ModalBodyInputs
            label="NIF"
            value={props.proveedor.nif}
            cols="col-span-2"
          />

          {/* RZN SOCIAL */}
          <ModalBodyInputs
            label="Razón Social"
            value={props.proveedor.razonsocial}
            cols="col-span-3"
          />

          {/* TELEFONO */}
          <ModalBodyInputs
            label="Teléfono"
            value={props.proveedor.tel}
            cols="col-span-2"
          />
          <div className="col-span-1 flex flex-col justify-center items-center">
            <div className="flex items-center justify-center">
              <a
                href={`tel:${props.proveedor.tel}`}
                className="bg-indigo-500 px-3 py-2 text-xs rounded"
              >
                Llamar
              </a>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <hr className="mx-36 border-b-2 border-teal-600/40" />
      <div className="grid grid-cols-2 bg-white/10 dark:bg-black/10 rounded-xl">
        {/* DIRECCIONES */}
        <div className="col-span-1 border-r-2">
          <h1 className="text-3xl font-medium text-center tracking-wide my-3 ml-5 font-sans text-gray-800 dark:text-gray-100">
            DIRECCIONES
          </h1>
          {props.direcciones?.map((direccion, index) => {
            return (
              <div key={index + direccion.codigo}>
                <button
                  className="grid grid-cols-6 hover:bg-gray-500/20 rounded m-2 mb-3 mt-4 p-2 focus:bg-gray-600/30 dark:focus:bg-gray-400/30"
                  onClick={() => {
                    setContactos(direccion.contactos);
                  }}
                >
                  {/* DESCRIPCION */}
                  <ModalBodyInputs
                    label="Descripción"
                    value={
                      direccion.descripcion
                        ? direccion.descripcion
                        : direccion.nombretipo
                    }
                    cols={"col-span-4"}
                  />

                  {/* CODIGO TIPO */}
                  <ModalBodyInputs
                    label="Código"
                    value={direccion.codtipo}
                    cols="col-span-2"
                  />

                  {/* PAIS */}
                  <ModalBodyInputs
                    label="País"
                    value={direccion.codpais}
                    cols="col-span-1"
                  />

                  {/* POBLACION */}
                  <ModalBodyInputs
                    label="Población"
                    value={direccion.pob}
                    cols="col-span-3"
                  />

                  {/* COD POSTAL */}
                  <ModalBodyInputs
                    label="Código Postal"
                    value={direccion.cp}
                    cols="col-span-2"
                  />

                  {/* DIRECCION */}
                  <ModalBodyInputs
                    label="Dirección"
                    value={direccion.dir}
                    cols="col-span-6"
                  />
                </button>
                <hr className="border-x-2 border-gray-400 mb-5 ml-20" />
              </div>
            );
          })}
        </div>
        {/* CONTACTOS */}
        <div className="col-span-1 border-l-2">
          <h1 className="text-3xl font-medium text-center tracking-wide my-3 ml-5 font-sans text-gray-800 dark:text-gray-100">
            CONTACTOS
          </h1>
          {contactos?.map((contacto) => {
            return (
              <>
                <div className="grid grid-cols-6 hover:bg-gray-500/20 rounded m-2 mb-3 mt-4 p-2">
                  {/* CARGO */}
                  <ModalBodyInputs
                    label="Cargo"
                    value={contacto.cargo}
                    cols="col-span-2"
                  />

                  {/* NOMBRE */}
                  <ModalBodyInputs
                    label="Nombre"
                    value={contacto.nombre}
                    cols={"col-span-4"}
                  />

                  {/* EMAIL */}
                  <ModalBodyInputs
                    label="Email"
                    value={contacto.email}
                    cols="col-span-5"
                  />

                  {/* EMAIL TO */}
                  <div className="col-span-1  flex items-center justify-center">
                    <a
                      href={`mailto:${contacto.email}`}
                      className="bg-indigo-400 px-3 py-2 text-xs rounded"
                    >
                      Email
                    </a>
                  </div>

                  {/* MOVIL */}
                  {contacto.movil ? (
                    <ModalBodyInputs
                      label="Móvil"
                      value={contacto.movil}
                      cols={contacto.tel ? "col-span-2" : "col-span-3"}
                    />
                  ) : (
                    ""
                  )}

                  {/* LLAMAR MOVIL */}
                  {contacto.movil ? (
                    <div className="col-span-1 flex items-center justify-center">
                      <a
                        href={`tel:${contacto.movil}`}
                        className="bg-indigo-400 px-3 py-2 text-xs rounded"
                      >
                        Movil
                      </a>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* TELEFONO */}
                  {contacto.tel ? (
                    <ModalBodyInputs
                      label="Teléfono"
                      value={contacto.tel}
                      cols={contacto.movil ? "col-span-2" : "col-span-3"}
                    />
                  ) : (
                    ""
                  )}

                  {/* LLAMAR TELEFONO */}
                  {contacto.tel ? (
                    <div className="col-span-1 flex items-center justify-center">
                      <a
                        href={`tel:${contacto.tel}`}
                        className="bg-indigo-400 px-3 py-2 text-xs rounded"
                      >
                        {"Teléfono"}
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <hr className="border-x-2 border-gray-400 mb-5 mr-20" />
              </>
            );
          })}
        </div>
      </div>
    </>
  );
}

interface PropsModalProveedoresBody {
  proveedor?: Proveedor;
  direcciones?: Direccion[];
  refresh: boolean;
}
