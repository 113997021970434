import { Navigate, Outlet, Route, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

export default function AuthorizeCheck(props: PropsAuthorizeCheck) {
  const { auth, setAuth } = useAuth();
  const location = useLocation();

  if (!auth) {
    return <Navigate to="/login" />;
  } else if (auth.activo === "0") {
    return <Navigate to="/login" />;
  } else if (auth.rol && props.roles.includes(auth.rol.id)) {
    return <Outlet />;
  } else {
    return <Navigate to="/" />;
  }
}

interface PropsAuthorizeCheck {
  roles: number[];
  children?: JSX.Element;
}
