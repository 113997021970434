import { NavLink, Outlet } from "react-router-dom";

export default function BtnDeleteTabla(props: OptionObject) {
  let positionBorderClass = "rounded";
  if (props.position == "LEFT") positionBorderClass = "rounded-l";
  if (props.position == "RIGHT") positionBorderClass = "rounded-r";
  if (props.position == "MIDDLE") positionBorderClass = "";

  if (props.url) {
    return (
      <NavLink
        to={props.url}
        className={"bg-red-500 py-2 px-4 " + positionBorderClass}
      >
        <svg fill="black" viewBox="0 0 20 20" className="w-5 h-5">
          <path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </NavLink>
    );
  } else if (props.action) {
    // Funcion al realizar click en el boton
    let functionOnClick = props.action;

    return (
      <button
        onClick={() => functionOnClick()}
        className={"bg-red-500 py-2 px-4 " + positionBorderClass}
      >
        <svg fill="black" viewBox="0 0 20 20" className="w-5 h-5">
          <path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    );
  }

  return <Outlet />;
}

interface OptionObject {
  url?: string;
  action?: Function;
  position: string;
}
