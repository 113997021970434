import { DOMElement, ReactHTML } from "react";

export default function TablaHead(props: PropsTableHead) {
  const trBody = props.arrayData.map((data, index) => {
    return (
      <th scope="col" className="py-3 px-6 text-center" key={index + data}>
        {data}
      </th>
    );
  });

  if (!props.arrayData) {
    return <h1>EMPTY HEAD</h1>;
  }
  return (
    <thead className="text-xs text-gray-900 uppercase bg-gray-300 dark:bg-gray-700 dark:text-neutral-300">
      <tr>{trBody}</tr>
    </thead>
  );
}

interface PropsTableHead {
  arrayData: any[];
}
