import { Routes, Route } from "react-router-dom";
import AuthorizeCheck from "../componentes/Auth/AuthorizeCheck";
import Layout from "../componentes/Layout/Layout";
import Clientes from "../paginas/clientes/Clientes";
import Home from "../paginas/home/Home";
import Login from "../paginas/login/Login";
import Logout from "../paginas/logout/Logout";
import Proveedores from "../paginas/proveedores/Proveedores";
import Usuarios from "../paginas/usuarios/Usuarios";

export function Rutas() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* PUBLIC */}
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />

        {/* LOGGED */}

        {/* ADMIN */}
        <Route element={<AuthorizeCheck roles={[3]} />}>
          <Route path="usuarios" element={<Usuarios />} />
        </Route>
        {/* OTHER */}
        <Route element={<AuthorizeCheck roles={[2]} />}>
          <Route path="clientes" element={<Clientes />} />
          <Route path="proveedores" element={<Proveedores />} />
        </Route>

        {/* LOG OUT */}
        <Route path="logout" element={<Logout />} />

        {/* ALL */}
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}
