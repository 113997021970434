import { useEffect, useState } from "react";
import Form from "../../componentes/Form/Form";
import FormInput from "../../componentes/Form/FormImput";
import FormSelect from "../../componentes/Form/FormSelect";
import ModalBasic from "../../componentes/Modales/ModalBasic";
import ModalConfiramcion from "../../componentes/Modales/ModalConfirmacion";
import BtnDeleteTabla from "../../componentes/Tabla/Componentes/BtnDeleteTabla";
import BtnEditTabla from "../../componentes/Tabla/Componentes/BtnEditTabla";
import TablaHead from "../../componentes/Tabla/TablaHead";
import TablaRow from "../../componentes/Tabla/TablaRow";
import { FormProvider } from "../../context/FormProvider";
import useAuth from "../../hooks/useAuth";
import useAxios from "../../hooks/useAxios";
import { Rol } from "../../modelos/Roles.model";
import { Usuario, UsuarioEdit } from "../../modelos/Usuarios.model";
import addCross from "./../../icons/addCross.svg";

export default function Usuarios() {
  // ALL USUARIOS
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  // ACTUAL USER (edit/delete/*)
  const [usuario, setUsuario] = useState<Usuario | UsuarioEdit | null>(null);
  // ALL ROLES
  const [roles, setRoles] = useState<Rol[]>([]);
  // TOGGLE FLAGS
  const [toggleModalFlagCreateEdit, setToggleModalFlagCreateEdit] =
    useState<boolean>(false);
  const [toggleModalFlagDelete, setToggleModalFlagDelete] =
    useState<boolean>(false);

  // AUTH CONTEXT
  const { auth } = useAuth();
  const { peticionUsuarios, peticionRoles } = useAxios();

  // Effects
  useEffect(() => {
    pedirAnhadirUsuarios();
    peticionRoles.getAll().then((res) => {
      if (!res.error) setRoles(res.data);
    });
  }, []);

  // * Funciones
  // TOGGLEOS
  function toggleModalCreateEdit() {
    setToggleModalFlagCreateEdit(!toggleModalFlagCreateEdit);
  }

  // SUBMITS
  async function submitCreate(formulario: any): Promise<Respuesta<any>> {
    let res = await peticionUsuarios.saveUser(formulario);
    if (!res.error) {
      pedirAnhadirUsuarios();
      toggleModalCreateEdit();
    }
    return res;
  }

  async function submitEdit(formulario: any): Promise<Respuesta<any>> {
    let id = usuario?.id ? usuario.id : 0;
    let res = await peticionUsuarios.editUser(id, formulario);
    if (!res.error) {
      pedirAnhadirUsuarios();
      toggleModalCreateEdit();
    }
    return res;
  }

  // OTROS
  function pedirAnhadirUsuarios() {
    peticionUsuarios.getAll().then((res) => {
      if (!res.error) setUsuarios(res.data);
    });
  }

  // Componentes
  const passORactive = () => {
    if (usuario === null) {
      return (
        <FormInput
          label="Password"
          name="password"
          type="password"
          clases="col-span-2 p-1 mb-4"
          required={true}
        />
      );
    } else {
      return (
        <>
          <FormInput
            label="Activo"
            name="activo"
            type="radio"
            clases="col-span-1 p-1 mb-4"
            value={"1"}
            required={true}
          />
          <FormInput
            label="Inactivo"
            name="activo"
            type="radio"
            clases="col-span-1 p-1 mb-4"
            value={"0"}
            required={true}
          />
        </>
      );
    }
  };

  return (
    <>
      <div className="flex flex-row justify-between">
        <h1 className="text-5xl font-medium text-left tracking-wide mb-6 ml-5 font-sans text-teal-800">
          USUARIOS
        </h1>
        <button
          className="border rounded-lg border-teal-700 bg-teal-600 hover:bg-teal-500 text-white text-center px-2 m-2 mr-4"
          onClick={() => {
            setUsuario(null);
            toggleModalCreateEdit();
          }}
        >
          <img src={addCross} alt="Crear nuevo usuario" />
        </button>
      </div>
      <div className="relative shadow-md sm:rounded-lg overflow-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <TablaHead
            arrayData={["ID", "Username", "Activo", "Rol", "Acciones"]}
          />
          <tbody>
            {usuarios.map((usuarioMap, index) => {
              let copyUser: any = { ...usuarioMap };
              copyUser!.rolNombre = usuarioMap.rol.nombre;
              delete copyUser!.rol;
              if (usuarioMap.id == auth!.id) {
                return (
                  <TablaRow jsonData={copyUser} key={index + usuarioMap.id}>
                    <h1>No Modificable</h1>
                  </TablaRow>
                );
              } else {
                return (
                  <TablaRow jsonData={copyUser} key={index + usuarioMap.id}>
                    <>
                      <BtnEditTabla
                        position="LEFT"
                        action={() => {
                          setUsuario(null);
                          setUsuario({
                            activo: usuarioMap.activo,
                            id: usuarioMap.id,
                            username: usuarioMap.username,
                            rol_id: usuarioMap.rol.id,
                          });
                          setToggleModalFlagCreateEdit(
                            !toggleModalFlagCreateEdit
                          );
                        }}
                      />
                      <BtnDeleteTabla
                        position="RIGHT"
                        action={() => {
                          setUsuario(null);
                          setUsuario(usuarioMap);
                          setToggleModalFlagDelete(!toggleModalFlagDelete);
                        }}
                      />
                    </>
                  </TablaRow>
                );
              }
            })}
          </tbody>
        </table>
      </div>

      {/* CREATE EDIT */}
      <ModalBasic
        title={usuario ? "Editar Usuario" : "Crear Usuario"}
        open={toggleModalFlagCreateEdit}
        setStateModal={setToggleModalFlagCreateEdit}
        widthClases="w-full max-w-xl"
      >
        <FormProvider>
          <Form
            submit={usuario === null ? submitCreate : submitEdit}
            initialValues={usuario ? usuario : {}}
          >
            <div
              className={
                usuario ? "grid grid-cols-4 gap-3" : "grid grid-cols-2 gap-3"
              }
            >
              <>
                <FormInput
                  label="Username"
                  name="username"
                  type="text"
                  clases="p-1 m-1"
                  required={true}
                />
                <FormSelect
                  label="Rol ID"
                  name="rol_id"
                  clases="p-1 m-1"
                  values={roles.map((rolData) => {
                    return { key: rolData.nombre, value: `${rolData.id}` };
                  })}
                />
                {passORactive()}
              </>
            </div>
          </Form>
        </FormProvider>
      </ModalBasic>

      {/* DELETE CONFIRM */}
      <ModalConfiramcion
        open={toggleModalFlagDelete}
        setStateModal={setToggleModalFlagDelete}
        title="Borrar Usuario"
        widthClases="max-w-xl"
        aceptar={() => {
          if (usuario?.id)
            peticionUsuarios.deleteUser(usuario.id).then((res) => {
              pedirAnhadirUsuarios();
              setToggleModalFlagDelete(!toggleModalFlagDelete);
            });
        }}
        rechazar={() => {
          setUsuario(null);
          setToggleModalFlagDelete(!toggleModalFlagDelete);
        }}
      >
        <h1 className="dark:text-white text-gray-900">
          Seguro que quiere borrar a <span>{usuario?.username}</span>
        </h1>
      </ModalConfiramcion>
    </>
  );
}
