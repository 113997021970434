import { Outlet } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";

export default function Layout(props: PropsLayout) {
  return (
    <div className="flex flex-nowrap bg-white dark:bg-gray-200" id="app">
      <Sidebar />
      <div className="container py-10 md:w-4/5 w-11/12 px-6 mx-auto">
        <div className="w-full h-full">
          {props.children ? props.children : <Outlet />}
        </div>
      </div>
    </div>
  );
}

interface PropsLayout {
  children?: JSX.Element;
}
