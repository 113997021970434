import { useEffect, useRef, useState } from "react";
import Logo from "../Logo/Logo";
import SidebarItem from "./Componentes/SidebarItem";

function Sidebar() {
  // Refs
  let mobileMenu = useRef(null);

  // States
  const [abrirMenu, setAbrirMenu] = useState(false);

  // Effects
  useEffect(() => {
    let nav = document.getElementById("mobile-nav");
    if (nav) {
      if (abrirMenu) {
        console.log(abrirMenu);
        nav.style.transform = "translateX(0px)";
      } else {
        nav.style.transform = "translateX(-260px)";
      }
    }
  }, [abrirMenu]);

  // Events
  const sidebarHandler = () => {
    setAbrirMenu(!abrirMenu);
  };

  const routes: Ruta[] = [
    { name: "Login", route: "/login", rolIds: [], none: true },
    { name: "Clientes", route: "/clientes", rolIds: [2] },
    { name: "Proveedores", route: "/proveedores", rolIds: [2] },
    { name: "Usuarios", route: "/usuarios", rolIds: [3] },
  ];

  return (
    <>
      {/* Sidebar starts */}
      {/* DESKTOP */}
      <div className="">
        <div className="w-64 bg-gray-800 shadow h-full flex-col justify-between hidden sm:flex sm:relative">
          <div className="p-2 px-8 mt-4  sticky top-4">
            <div className="w-full flex flex-col items-center">
              <Logo></Logo>
            </div>
            <ul className="mt-10">
              {routes.map((ruta: Ruta, key) => (
                <SidebarItem rutaElement={ruta} key={key + ruta.name} />
              ))}
            </ul>
          </div>
          <div className="p-2 px-10 mb-10 sticky bottom-10">
            <SidebarItem
              rutaElement={{
                name: "Cerrar Sessión",
                route: "/logout",
                rolIds: [],
                all: true,
              }}
            />
          </div>
        </div>
      </div>
      {/* MOBILE */}
      <div
        className="w-64 z-40 h-full bg-gray-800 shadow md:h-full flex-col justify-between sm:hidden transition duration-150 ease-in-out fixed"
        id="mobile-nav"
        style={{ transform: "translate(-260px)" }}
      >
        {/* MOBILE TOGGLER */}
        <button
          onClick={() => sidebarHandler()}
          className="h-10 w-10 bg-gray-800 absolute right-0 mt-16 -mr-10 flex items-center shadow rounded-tr rounded-br justify-center cursor-pointer"
          id="mobile-toggler"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-adjustments"
            width={20}
            height={20}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#FFFFFF"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx={6} cy={10} r={2} />
            <line x1={6} y1={4} x2={6} y2={8} />
            <line x1={6} y1={12} x2={6} y2={20} />
            <circle cx={12} cy={16} r={2} />
            <line x1={12} y1={4} x2={12} y2={14} />
            <line x1={12} y1={18} x2={12} y2={20} />
            <circle cx={18} cy={7} r={2} />
            <line x1={18} y1={4} x2={18} y2={5} />
            <line x1={18} y1={9} x2={18} y2={20} />
          </svg>
        </button>
        <div className="p-2 px-8 mt-4 h-full flex flex-col content-between justify-between">
          <div className="">
            <div className="w-full flex flex-col items-center">
              <Logo></Logo>
            </div>
            <ul className="mt-10">
              {routes.map((ruta: Ruta, key) => (
                <SidebarItem rutaElement={ruta} key={key + ruta.name} />
              ))}
            </ul>
          </div>
          <div className="p-2 mb-10">
            <SidebarItem
              rutaElement={{
                name: "Cerrar Sessión",
                route: "/logout",
                rolIds: [],
                all: true,
              }}
            />
          </div>
        </div>
      </div>
      {/* Sidebar ends */}
    </>
  );
}

export default Sidebar;
