import React, { createContext, useState } from "react";
import { Rol } from "../modelos/Roles.model";
import { Usuario } from "../modelos/Usuarios.model";

// CONTEXT
const AuthContext = createContext<AuthContext>({
  auth: null,
  setAuth: null,
  isUserLogged: () => {
    return false;
  },
  getActualUserRole: () => {
    return null;
  },
  resetUser: () => {},
  isActive: () => {
    return false;
  },
  apiKeys: { token_auth: "", token_data: "" },
  setApiKeys: () => {},
  resetApiKeys: () => {},
});

// Proveedor de USUARIO
export function AuthProvider(props: PropsAuthProvider) {
  // Usuario default
  const [auth, setAuth] = useState<Usuario | null>(null);

  // Api Keys
  const [apiKeys, setApiKeys] = useState<ApiKeys>({
    token_auth: "",
    token_data: "",
  });

  // Funciones
  function isUserLogged() {
    if (auth === null) return false;
    return true;
  }
  function isActive() {
    if (auth === null) return false;
    return auth.activo === "1";
  }
  function getActualUserRole() {
    if (auth === null) return null;
    else return auth.rol;
  }
  function resetUser() {
    setAuth(null);
  }
  function resetApiKeys() {
    setApiKeys({ token_auth: "", token_data: "" });
  }

  // Provider
  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        isUserLogged,
        getActualUserRole,
        resetUser,
        isActive,
        apiKeys,
        setApiKeys,
        resetApiKeys,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

// Interfaces
interface PropsAuthProvider {
  children: JSX.Element;
}

interface AuthContext {
  auth: Usuario | null;
  setAuth: React.Dispatch<React.SetStateAction<Usuario | null>> | null;
  isUserLogged: () => boolean;
  getActualUserRole: () => Rol | null;
  resetUser: () => void;
  isActive: () => boolean;
  apiKeys: ApiKeys;
  setApiKeys: React.Dispatch<React.SetStateAction<ApiKeys>>;
  resetApiKeys: () => void;
}

// Export DEF
export default AuthContext;
