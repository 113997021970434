import React, {
  ChangeEvent,
  ChangeEventHandler,
  createContext,
  FormEventHandler,
  useState,
} from "react";

// CONTEXT
const FormContext = createContext<FormContext>({
  form: {},
  handleFormChange: () => {},
  setForm: () => {},
  getFormValue: () => {},
  clearForm: () => {},
});

// Proveedor de FORMULARIO
export function FormProvider(props: PropsFormProvider) {
  let newForm = props.initialValues ? props.initialValues : {};

  // Valores iniciales del formulario
  const [form, setForm] = useState(newForm);

  // Funcion para alterar el formulario del context (set state del form)
  const handleFormChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;

    // console.log(ev.target.name);
    // console.log(ev.target.value);

    // Modificamos el valor del formulario
    setForm({
      ...form,
      [name]: value,
    });
  };

  const getFormValue = (key: string) => {
    return { ...form }[key];
  };

  // Funcion para limpiar el formulario
  const clearForm = () => {
    setForm({});
  };

  // Provider
  return (
    <FormContext.Provider
      value={{ form, handleFormChange, setForm, getFormValue, clearForm }}
    >
      {props.children}
    </FormContext.Provider>
  );
}

// Interfaces
interface PropsFormProvider {
  children: JSX.Element;
  initialValues?: {};
}

interface FormContext {
  form: {};
  handleFormChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  setForm: React.Dispatch<React.SetStateAction<{}>>;
  getFormValue: Function;
  clearForm: Function;
}

// Export DEF
export default FormContext;
