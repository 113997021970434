import useForm from "../../hooks/useForm";

export default function FormSelect(props: PropsFormSelect) {
  const { handleFormChange, getFormValue } = useForm();

  return (
    <div className={props.clases ? props.clases : ""}>
      <label className="block uppercase tracking-wide text-gray-800 dark:text-gray-100 text-xs font-bold mb-2 text-left">
        {props.label}
      </label>
      <select
        name={props.name}
        className="appearance-none block w-full bg-gray-700 dark:bg-gray-200 text-gray-100 dark:text-gray-800 border rounded py-1 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-900 dark:focus:bg-gray-100"
        onChange={handleFormChange}
        value={props.value ? props.value : getFormValue(props.name)}
      >
        <option value={-1}>Seleccione</option>
        {props.values.map((val, index) => {
          return (
            <option value={val.value} key={index + val.value}>
              {val.key}
            </option>
          );
        })}
      </select>
    </div>
  );
}

interface PropsFormSelect {
  label: string;
  name: string;
  values: KeyValue[];
  clases?: string;
  required?: boolean;
  value?: string | number;
}

interface KeyValue {
  key: string;
  value: string;
}
