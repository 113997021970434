import axios from "axios";
import { reqBaseCreator } from "../api/axios";
import { Cliente } from "../modelos/Clientes.model";
import { Direccion } from "../modelos/Direccion.model";
import { Proveedor } from "../modelos/Proveedores.model";
import { Rol } from "../modelos/Roles.model";
import {
  Usuario,
  UsuarioCreate,
  UsuarioLogin,
} from "../modelos/Usuarios.model";
import useAuth from "./useAuth";

export default function useAxios(): UseAxiosReturn {
  // API KEYS from AUTH
  const { apiKeys, setApiKeys, resetApiKeys } = useAuth();

  // Rutas
  const RUTAS = {
    auth: `${process.env.REACT_APP_URL_AUTH}`,
    data: `${process.env.REACT_APP_URL_DATA}`,
  };

  // BASE CONFIGS
  const peticionData = axios.create({
    baseURL: RUTAS.data,
    headers: {
      ApiKey: `${apiKeys.token_data}`,
      "api-version": "2.0",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const peticionAuth = axios.create({
    baseURL: RUTAS.auth,
    headers: {
      ApiKey: `${apiKeys.token_auth}`,
      "api-version": "1.0",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const peticionLogin = axios.create({
    baseURL: RUTAS.auth,
    headers: {
      "api-version": "1.0",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const reqData = reqBaseCreator(peticionData);
  const reqAuth = reqBaseCreator(peticionAuth);
  const reqLog = reqBaseCreator(peticionLogin);

  // * DATA

  // CLIENTES
  const peticionClientes: PeticionClientes = {
    getAll: () => reqData.get("/clientes"),
    getByCodigo: (codigo: number) => reqData.get(`/clientes/${codigo}`),
    getDirecciones: (codigo: number) =>
      reqData.get(`/clientes/${codigo}/direcciones`),
  };

  // PROVEEDORES
  const peticionProveedores: PeticionProveedores = {
    getAll: () => reqData.get("/proveedores"),
    getByCodigo: (codigo: number) => reqData.get(`/proveedores/${codigo}`),
    getDirecciones: (codigo: number) =>
      reqData.get(`/proveedores/${codigo}/direcciones`),
  };

  // * AUTH

  // USUARIOS
  const peticionUsuarios: PeticionUsuarios = {
    getAll: () => reqAuth.get("/Usuarios"),
    getById: (id: string | number) => reqAuth.get(`/Usuarios/${id}`),
    saveUser: (usuario: UsuarioCreate) => reqAuth.post(`/Usuarios`, usuario),
    loginUser: (username: string, password: string) =>
      reqLog.post(`/Usuarios/login`, { username, password }),
    editUser: (id: string | number, usuario: Usuario) =>
      reqAuth.put(`/Usuarios/${id}`, usuario),
    editUserPassword: (id: string | number, password: string) =>
      reqAuth.put(`/Usuarios/${id}`, password),
    deleteUser: (id: string | number) => reqAuth.delete(`/Usuarios/${id}`),
  };

  // ROLES
  const peticionRoles: PeticionRoles = {
    getAll: () => reqAuth.get("/Roles"),
    getById: (id: string | number) => reqAuth.get(`/Roles/${id}`),
  };

  return {
    peticionClientes,
    peticionProveedores,
    peticionUsuarios,
    peticionRoles,
  };
}

interface UseAxiosReturn {
  peticionClientes: PeticionClientes;
  peticionProveedores: PeticionProveedores;
  peticionUsuarios: PeticionUsuarios;
  peticionRoles: PeticionRoles;
}

interface PeticionClientes {
  getAll: () => Promise<Respuesta<Cliente[]>>;
  getByCodigo: (codigo: number) => Promise<Respuesta<Cliente>>;
  getDirecciones: (codigo: number) => Promise<Respuesta<Direccion[]>>;
}

interface PeticionProveedores {
  getAll: () => Promise<Respuesta<Proveedor[]>>;
  getByCodigo: (codigo: number) => Promise<Respuesta<Proveedor>>;
  getDirecciones: (codigo: number) => Promise<Respuesta<Direccion[]>>;
}

interface PeticionUsuarios {
  getAll: () => Promise<Respuesta<Usuario[]>>;
  getById: (id: string | number) => Promise<Respuesta<Usuario>>;
  saveUser: (usuario: UsuarioCreate) => Promise<Respuesta<any>>;
  loginUser: (
    username: string,
    password: string
  ) => Promise<Respuesta<UsuarioLogin>>;
  editUser: (id: string | number, usuario: Usuario) => Promise<Respuesta<any>>;
  editUserPassword: (
    id: string | number,
    password: string
  ) => Promise<Respuesta<any>>;
  deleteUser: (id: string | number) => Promise<Respuesta<any>>;
}

interface PeticionRoles {
  getAll: () => Promise<Respuesta<Rol[]>>;
  getById: (id: string | number) => Promise<Respuesta<Rol>>;
}
