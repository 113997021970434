import { AxiosInstance, AxiosResponse } from "axios";

export const responseBody = (response: AxiosResponse) => {
  let error = true;
  if (response.status >= 200 && response.status < 300) error = false;
  return { data: response.data, error };
};

export const reqBaseCreator = (petBase: AxiosInstance) => {
  return {
    get: (url: string) =>
      petBase.get(url).then(responseBody).catch(responseBody),
    post: (url: string, body: any) =>
      petBase.post(url, body).then(responseBody).catch(responseBody),
    put: (url: string, body: any) =>
      petBase.post(url, body).then(responseBody).catch(responseBody),
    delete: (url: string, body?: any) =>
      petBase.post(url, body).then(responseBody).catch(responseBody),
  };
};
