import { useEffect, useState } from "react";
import ModalBasic from "../../componentes/Modales/ModalBasic";
import TablaHead from "../../componentes/Tabla/TablaHead";
import TablaRow from "../../componentes/Tabla/TablaRow";
import useAxios from "../../hooks/useAxios";
import { Direccion } from "../../modelos/Direccion.model";
import { Proveedor } from "../../modelos/Proveedores.model";
import ModalProveedoresBody from "./componentes/ModalProveedoresBody";

function Proveedores() {
  // States
  const [proveedores, setProveedores] = useState<Proveedor[]>([]);
  const [toggleModalFlag, setToggleModalFlag] = useState(false);
  const [proveedor, setProveedor] = useState<Proveedor>();
  const [direcciones, setDirecciones] = useState<Direccion[]>([]);

  // Hooks
  const { peticionProveedores } = useAxios();

  // Effects
  useEffect(() => {
    peticionProveedores.getAll().then((res) => {
      if (!res.error) setProveedores(res.data);
    });
  }, []);

  // Functions
  function toggleModal(proveedorModal: Proveedor) {
    setProveedor(proveedorModal);
    peticionProveedores.getDirecciones(proveedorModal.codigo).then((res) => {
      if (!res.error) setDirecciones(res.data);
    });

    setToggleModalFlag(!toggleModalFlag);
    // console.log(toggleModalFlag);
  }

  // ELEMENTOS
  let tableBodyProveedores = proveedores.map((data, index) => {
    return (
      <TablaRow
        jsonData={data}
        rowClick={() => toggleModal(data)}
        key={index + data.codigo}
      >
        <>
          <div className="flex items-center justify-center">
            <a
              href={`tel:${data.tel}`}
              className="bg-indigo-500 px-3 py-2 text-xs rounded"
            >
              Llamar
            </a>
          </div>
        </>
      </TablaRow>
    );
  });

  return (
    <>
      <h1 className="text-5xl font-medium text-left tracking-wide mb-6 ml-5 font-sans text-teal-800">
        PROVEEDORES
      </h1>
      <div className="relative shadow-md sm:rounded-lg overflow-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <TablaHead
            arrayData={[
              "Codigo",
              "Nombre Comercial",
              "Razón Social",
              "NIF",
              "Teléfono",
              "Acciones",
            ]}
          />
          <tbody>{tableBodyProveedores}</tbody>
        </table>
      </div>
      <ModalBasic
        open={toggleModalFlag}
        setStateModal={setToggleModalFlag}
        title="Proveedor"
      >
        <ModalProveedoresBody
          proveedor={proveedor}
          direcciones={direcciones}
          refresh={toggleModalFlag}
        />
      </ModalBasic>
    </>
  );
}

export default Proveedores;
