import { NavLink, Outlet } from "react-router-dom";
import EditIcon from "./../../../icons/edit.svg";

export default function BtnEditTabla(props: OptionObject) {
  let positionBorderClass = "rounded";
  if (props.position == "LEFT") positionBorderClass = "rounded-l";
  if (props.position == "RIGHT") positionBorderClass = "rounded-r";
  if (props.position == "MIDDLE") positionBorderClass = "";

  if (props.url) {
    return (
      <NavLink
        to={props.url}
        className={"bg-teal-500 py-2 px-4 " + positionBorderClass}
      >
        <svg fill="black" viewBox="0 0 20 20" className="w-5 h-5">
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
        </svg>
      </NavLink>
    );
  } else if (props.action) {
    // Funcion al realizar click en el boton
    let functionOnClick = props.action;

    return (
      <button
        onClick={() => functionOnClick()}
        className={"bg-teal-500 py-2 px-4 " + positionBorderClass}
      >
        <svg fill="black" viewBox="0 0 20 20" className="w-5 h-5">
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
        </svg>
      </button>
    );
  }

  return <h1>Configura el boton</h1>;
}

interface OptionObject {
  url?: string;
  action?: Function;
  position: string;
}
