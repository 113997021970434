import LoginImage from "../../assets/images/Logo-PlaceIn_DEGRADADO.png";
import useAuth from "../../hooks/useAuth";
import React, { createRef } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import useAxios from "../../hooks/useAxios";
import Usuarios from "../usuarios/Usuarios";
import { Usuario } from "../../modelos/Usuarios.model";

function Login() {
  // SWAL REACT
  const MySwal = withReactContent(Swal);

  // HOOKS
  const { auth, setAuth, setApiKeys } = useAuth();
  const navigate = useNavigate();
  const { peticionUsuarios } = useAxios();

  // REFS
  const inputName = React.useRef<HTMLInputElement>(null);
  const inputPass = React.useRef<HTMLInputElement>(null);

  // FUNCTIONS
  const logguearse = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (inputName.current!.value != "" && inputPass.current!.value != "") {
      let res = await peticionUsuarios.loginUser(
        inputName.current!.value,
        inputPass.current!.value
      );

      if (!res.error && setAuth && res.data!.activo === "1") {
        const { apikeys, ...user } = res.data;
        setAuth(user);
        setApiKeys(apikeys);
        navigate("/");
      } else if (!res.error && res.data!.activo === "0") {
        MySwal.fire({
          title: `Su usuario está deshabilitado, hable con su administrador.`,
          icon: "warning",
          timer: 5000,
          position: "top",
          confirmButtonText: "Entendido",
          confirmButtonColor: "teal",
          showCloseButton: true,
        });
      } else {
        MySwal.fire({
          title: "Revise sus datos",
          icon: "error",
          timer: 5000,
          position: "top",
          confirmButtonColor: "teal",
          showCloseButton: true,
        });
      }
    }
  };

  return (
    <>
      <div className="flex justify-center items-center flex-wrap h-full text-gray-800">
        <div className="md:w-8/12 lg:w-6/12">
          <img
            src={LoginImage}
            alt="PlaceIn Logo"
            className="w-full translate-x-0 lg:translate-x-8  scale-100 lg:scale-90 bg-gradient-to-br from-zinc-200/90 to-zinc-300/90 rounded-xl shadow-xl"
          />
        </div>
        <div className="md:w-8/12 lg:w-5/12 lg:ml-20">
          <form onSubmit={logguearse}>
            {/* <!-- Email input --> */}
            <div className="mb-6">
              <input
                ref={inputName}
                name="username"
                type="text"
                className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-teal-600 focus:outline-none"
                placeholder="Username"
                required={true}
              />
            </div>

            {/* <!-- Password input --> */}
            <div className="mb-6">
              <input
                ref={inputPass}
                name="password"
                type="password"
                className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-teal-600 focus:outline-none"
                placeholder="Password"
                required={true}
              />
            </div>

            {/* <div className="flex justify-between items-center mb-6">
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-teal-600 checked:border-teal-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  id="exampleCheck3"
                  checked
                />
                <label className="form-check-label inline-block text-gray-800">
                  --- / --- for="exampleCheck2"
                  Remember me
                </label>
              </div>
              <a
                href="#!"
                className="text-teal-600 hover:text-teal-700 focus:text-teal-700 active:text-teal-800 duration-200 transition ease-in-out"
              >
                Forgot password?
              </a>
            </div> */}

            {/* <!-- Submit button --> */}
            <button
              type="submit"
              className="inline-block px-7 py-3 bg-teal-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:scale-105 hover:bg-teal-700 hover:shadow-lg focus:bg-teal-700 focus:scale-105 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-teal-800 active:shadow-lg transition duration-150 ease-in-out w-full"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
