import React, { useEffect } from "react";
import useForm from "../../hooks/useForm";

export default function Form(props: PropsForm) {
  const { form, clearForm, setForm } = useForm();

  useEffect(() => {
    if (props.initialValues) setForm(props.initialValues);
  }, [props.initialValues]);

  // Funciones
  function submitEv(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    console.log(form);
    props.submit(form).then((resolve) => {
      // console.log(resolve);
      if (!resolve.error) {
        clearForm();
        event.currentTarget?.reset();
      }
    });
  }

  return (
    <form onSubmit={submitEv} onReset={() => clearForm()}>
      {props.children}
      <div className="flex flex-row justify-around">
        <button
          type="submit"
          className="rounded border-teal-600 bg-teal-600 text-gray-50 px-5 py-2"
        >
          Enviar
        </button>
        <button
          type="reset"
          className="rounded border-red-600 bg-red-600 text-gray-50 px-5 py-2"
        >
          Limpiar
        </button>
      </div>
    </form>
  );
}

interface PropsForm {
  children: JSX.Element;
  submit: (formulario: any) => Promise<Respuesta<any>>;
  initialValues?: {};
}
