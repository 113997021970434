import { useEffect, useState } from "react";
import ModalBasic from "../../componentes/Modales/ModalBasic";
import TablaHead from "../../componentes/Tabla/TablaHead";
import TablaRow from "../../componentes/Tabla/TablaRow";
import useAxios from "../../hooks/useAxios";
import { Cliente } from "../../modelos/Clientes.model";
import { Direccion } from "../../modelos/Direccion.model";
import ModalClienteBody from "./componentes/ModalClienteBody";

export default function Clientes() {
  // States
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [toggleModalFlag, setToggleModalFlag] = useState(false);
  const [cliente, setCliente] = useState<Cliente>();
  const [direcciones, setDirecciones] = useState<Direccion[]>([]);

  // Hooks
  const { peticionClientes } = useAxios();

  // Effects
  useEffect(() => {
    peticionClientes.getAll().then((res) => {
      if (!res.error) setClientes(res.data);
    });
  }, []);

  // Functions
  function toggleModal(clienteModal: Cliente) {
    setCliente(clienteModal);
    peticionClientes.getDirecciones(clienteModal.codigo).then((res) => {
      if (!res.error) setDirecciones(res.data);
    });

    setToggleModalFlag(!toggleModalFlag);
    // console.log(toggleModalFlag);
  }

  // Table Body
  let tableBodyClientes = clientes.map((data: Cliente) => {
    return (
      <TablaRow jsonData={data} rowClick={() => toggleModal(data)}>
        <>
          <div className="flex items-center justify-center">
            <a
              href={`tel:${data.tel}`}
              className="bg-indigo-500 px-3 py-2 text-xs rounded"
            >
              Llamar
            </a>
          </div>
        </>
      </TablaRow>
    );
  });

  return (
    <>
      <h1 className="text-5xl font-medium text-left tracking-wide mb-6 ml-5 font-sans text-teal-800">
        CLIENTES
      </h1>
      <div className="relative shadow-md sm:rounded-lg overflow-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <TablaHead
            arrayData={[
              "Codigo",
              "Nombre Comercial",
              "Razón Social",
              "NIF",
              "Teléfono",
              "Acciones",
            ]}
          />
          <tbody>{tableBodyClientes}</tbody>
        </table>
      </div>
      <ModalBasic
        open={toggleModalFlag}
        setStateModal={setToggleModalFlag}
        title="Cliente"
      >
        <ModalClienteBody
          cliente={cliente}
          direcciones={direcciones}
          refresh={toggleModalFlag}
        />
      </ModalBasic>
    </>
  );
}

// footer={
//   <>
//     <button
//       type="button"
//       className="text-white bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800"
//     >
//       I accept
//     </button>
//     <button
//       type="button"
//       className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
//     >
//       Decline
//     </button>
//   </>
// }
