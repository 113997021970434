import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./context/AuthProvider";
import { Rutas } from "./rutas/Rutas";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <>
          <Rutas />
        </>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
