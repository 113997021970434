import { NavLink } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import applicationIcon from "./../../../icons/application.svg";

function SidebarItem(props: PropsSidebarItem) {
  const { auth, isUserLogged, getActualUserRole } = useAuth();
  // if (
  //   (props.rutaElement.rolIds.length == 0 && !isUserLogged()) ||
  //   (auth?.rol && props.rutaElement.rolIds.includes(auth.rol.id))
  // ) {
  let rol = auth && props.rutaElement.rolIds.includes(auth.rol.id);
  if (
    props.rutaElement.all === isUserLogged() ||
    props.rutaElement.none === !isUserLogged() ||
    rol
  ) {
    return (
      <li className="flex w-full justify-between text-gray-300 hover:text-gray-500 cursor-pointer items-center mb-6">
        <div className="flex items-center">
          <img src={applicationIcon} alt="Imagen aplicacion" />
          <span className="text-md lg:text-lg  ml-2 text-teal-500 hover:text-teal-600 hover:translate-x-0.5">
            <NavLink
              to={props.rutaElement.route}
              className={({ isActive }) =>
                isActive
                  ? "text-teal-400 border-b-2 border-teal-700/50 pr-2 hover:text-teal-600"
                  : "pr-2"
              }
            >
              {props.rutaElement.name}
            </NavLink>
          </span>
        </div>
      </li>
    );
  } else return <></>;
}

export default SidebarItem;

interface PropsSidebarItem {
  rutaElement: Ruta;
}
