export default function TablaRow(props: PropsTableRow) {
  const trBody = Object.values(props.jsonData).map((data: any, index) => {
    return (
      <td className="py-3 px-4" key={index + data}>
        {data}
      </td>
    );
  });

  if (!props.jsonData) {
    return <h1>Empty Row</h1>;
  }
  let functionOnClick = props.rowClick ? props.rowClick : () => {};

  return (
    <tr
      onClick={() => functionOnClick()}
      className="text-gray-800 dark:text-gray-300 odd:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-300 dark:hover:bg-gray-700 bg-gray-200 dark:odd:bg-gray-900 border-b border-gray-300"
    >
      {trBody}

      {props.children ? (
        <td className="py-3 px-4 flex flex-row justify-center">
          {props.children}
        </td>
      ) : (
        ""
      )}
    </tr>
  );
}

interface PropsTableRow {
  jsonData: {};
  rowClick?: Function;
  children?: JSX.Element;
}
